import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { API } from 'aws-amplify'
import { createContact } from '../graphql/mutations'

import "react-toastify/dist/ReactToastify.css";

const Contact = ({ classicHeader, darkTheme }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [sendingMail, setSendingMail] = useState(false);

  const resetForm = () => {
    setName("")
    setEmail("")
    setMessage("")
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);

    API.graphql({
      query: createContact,
      variables: {
        input: {
          name,
          email,
          message
        },
      },
    }).then(
      (result) => {
        resetForm()
        toast.success("Message sent successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkTheme ? "dark" : "light",
        });
        console.log(result);
        setSendingMail(false);
      },
      (error) => {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: darkTheme ? "dark" : "light",
        });
        console.log(error);
        setSendingMail(false);
      }
    );
  };

  return (
    <section
      id="contact"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      style={{ minHeight: 850 }}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")} style={{ marginBottom: 50 }}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Contact
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Get in Touch
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Contact
            </h2>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              Madison, WI 53711
            </p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              <a href={`tel:${16086691598}`}>(608) 669-1598</a>

            </p>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              <a href="mailto:contact@nathaniel-wallace.com">contact@nathaniel-wallace.com</a>

            </p>

          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1">
            <h2
              className={
                "mb-3 text-5 text-uppercase text-center text-md-start " +
                (darkTheme ? "text-white" : "")
              }
            >
              Send me a note
            </h2>
            <form
              className={darkTheme ? "form-dark" : ""}
              id="contact-form"
              action="php/mail.php"
              method="post"
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-xl-6">
                  <input
                    onChange={e => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    required
                    placeholder="Name"
                    value={name}
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email"
                    value={email}
                  />
                </div>
                <div className="col">
                  <textarea
                    onChange={e => setMessage(e.target.value)}
                    className="form-control"
                    rows={5}
                    placeholder="Tell me more about your needs..."
                    defaultValue={""}
                    value={message}
                  />
                </div>
              </div>
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary rounded-pill d-inline-flex"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        class="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Sending.....
                    </>
                  ) : (
                    <>Send Message</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
